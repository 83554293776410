body {
    height: 100%;
    position: fixed;    
}

html {
    overflow: hidden;
    width: 100%;
}

td,
th
{
    display: table-cell;

    padding: 0px 0px;

    text-align: left;
    vertical-align: middle;

    border-radius: 2px;
}

.invisible {
    display: none !important
}

.display_missions_arrow_inactive:after
{
    font-family: 'Material Icons';
    font-size: 18px;

    content: 'keyboard_arrow_right';
    transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s, -webkit-transform .3s ease-in-out; 

    color: #0000ff;
}

.display_missions_arrow_active:after {
    font-family: 'Material Icons';
    font-size: 18px;

    content: 'keyboard_arrow_down';
    transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s, -webkit-transform .3s ease-in-out; 

    color: #0000ff;
}

.display_missions_arrow_active:hover:after{
    font-family: 'Material Icons';
    font-size: 18px;

    content: 'visibility';
    transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s, -webkit-transform .3s ease-in-out; 

    color: #0000ff;
}


.display_missions_arrow_dummmy:after {
    font-family: 'Material Icons';
    font-size: 18px;

    content: 'keyboard_arrow_down';
    transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s, -webkit-transform .3s ease-in-out; 

    color: #0000ff;
}

.fab-content-head {
    height: 0px;        
}

.fab-content-height {
    height: 78vh;  
}

.fab-info-text-fontstyle {    
    font-size: 0.85rem; 
    color: #616161;
}

.fab-info-text
{
    font-size: .85rem; 

    overflow: hidden;

    width: calc(100% - 3em);

    white-space: nowrap;
    text-overflow: ellipsis;

    color: #616161;
}

.fab-sidebar-width {
    width: 300px !important;
}

.fab-sidebar-width:hover {
    width: 300px !important;
}

.fab-thumb, .fab-thumb-sc {    
    width: 3rem; 
    height: 3rem;
    object-position: center;
    object-fit: cover
}

.fab-thumb-1rem {    
    width: 1rem !important;
    height: 1rem !important;
    object-position: center;
    object-fit: cover
}

.fab-thumb-sc:hover {
    border-radius: 0%;
    transition: transform .2s;
    transform: scale(2.5);
    z-index: 999;
}

.fab-thumb-carousel {    
    width: inherit !important;
    height: inherit !important;
    object-position: center;
    object-fit: cover;
}

.fab-vertical-resizer, .resizer {
    background-color: #4286f4;
    height: 5px;
    cursor: row-resize;
}

.cursor-resize-ns {
    cursor: ns-resize;
}

.cursor-resize-ew {
    cursor: ew-resize;
}

.fab-mr-1em {
    margin-right: 1em;
}

.fab-switch-3 {    
    float: left;
    background: #242829;    
}

.fab-switch-3 input {
    position: absolute;
    opacity: 0;
}

.fab-switch-3 input + label {        
    padding: 7px;
    float: left;
    color: #fff;
    cursor: pointer;
    width: 8em;    
    
    border: 0.1em solid dimgrey;
}

/* .fab-switch-3 input[data-value=OK]:checked + label {
    background: green;
}

.fab-switch-3 input[data-value=FD]:checked + label {
    background: red;
}

.fab-switch-3 input[data-value=IP]:checked + label {
    background: grey;
}
*/

.fab-header-height {
    height: 64px;
}

.fab-main-height {
    height: calc(100vh - 64px);
}

.fab-main-inner-content {    
    top: calc(56px + 32px);
}

.fab-main-inner-height {
    height: calc(100vh - 64px);
    /* '--56px - 4rem); */
}

.fab-dragdestination {
    background-color:  aquamarine;
}

.fab-highlight {
    color: crimson;
    border: 3px solid #aa2222;
}

.fab-highlight:hover {
    color: red;
    border: 5px solid #ff2222;
}

.fab-center {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1280px) {
    .fab-width-navi-A {
        width: 300px;
    }

    .fab-width-navi-B {
        width: 300px;
    }

    .fab-width-main-C {
        width: calc(100% - 600px);
    }

    .fab-width-main-D {
        width: calc(100% - 600px);
    }
}

@media only screen and (max-width: 1280px) {
    .fab-width-navi-A {
        width: 100vw !important;
        flex: 0 0 100%;
    }

    .fab-width-navi-B {
        width: 100vw !important;
        flex: 0 0 100%;
    }

    .fab-width-main-C {
        width: 100vw !important;
        flex: 0 0 100%;
    }

    .fab-width-main-D {
        width: 100vw !important;
        flex: 0 0 100%;
    }
}

.fab-sidenav {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.fab-sidenav-width-collapsed {
    width: 0px;
}

.fab-swapping {  

}

.fab-full-screen-size {
    top: 0px ! important;
    left: 0px ! important;
    width: 100vw ! important;
    height: 100vh ! important;
    max-height: 100% ! important;
}

.fab-no-margin {
    
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

@media only screen and (max-height: 768px) {
    .fab-hide-on-low-height {
        display: none !important;
    }
}

.modal-content .fab-headline {
    font-size: 2rem;
}

.bg-logo {
    width: 47px;
    content: url('../../images/logo/materialize-logo-color.png');

    -webkit-background-size: cover;
    background-size: cover;
}

.html-part-preloader {
    content: url('../../html/custom/preloader01.html');
}

.kml-plz2 {
    content: url('../../kml/plz-2stellig.kml');
}

.geojson-plz2 {
    content: url('../../kml/plz-2stellig.geojson');
}

.geojson-plz3 {
    content: url('../../kml/plz-3stellig.geojson');
}

.geojson-plz5 {
    content: url('../../kml/plz-5stellig.geojson');
}


/* Add a green text color and a checkmark when the requirements are right */
.valid {
    color: green;
    margin-left: 35px;
  }
  
  .valid:before {
    position: relative;
    left: -35px;
    content: "✔";
  }
  
  /* Add a red text color and an "x" icon when the requirements are wrong */
  .invalid {
    color: red;
    margin-left: 35px;
  }
  
  .invalid:before {
    position: relative;
    left: -35px;
    content: "✖";
  }

  .fab-flexbox-center {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    overflow: hidden;
  }

  .fab-flexbox-left {
    display: flex; 
    align-items: left; 
    justify-content: left; 
    overflow: hidden;
  }

.fabbi-file-upload > input[type="file"] {
    display: none;
}

.fabbi-file-upload {
    color: white !important;
    cursor: pointer;
}

.fabbi_container_row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    align-content: space-between;

    position: absolute;
    top: 0px;
    left: 0px;
    width: inherit;
    height: 100%;

    opacity: 90%;
    background-color: whitesmoke;
    -webkit-transition: background-color .3s;
    -moz-transition: background-color .3s;
      -o-transition: background-color .3s;
         transition: background-color .3s;
}

.fabbi_container_row .row_item {
    flex: 1 0 auto;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.fab-ticker {    
    width: 80vw; 
    z-index: 0;
    height: 4rem;  
    line-height: 1.5rem;
}

.fab-search {    
    width: 80vw; 
    z-index: 0;
    height: 8rem;  
    line-height: 1.5rem;
}

.fab-perfect-scrollbar-y1 {
    position: absolute;  
}

.fab-perfect-scrollbar-y2 {
    position: absolute;  
}

.info-line {
    width: inherit;
    overflow: hidden;        
    margin: 5px;
}

.fab-time-01 {
    font-size: 0.8rem
}

.fab-list-entry-01 {
    cursor: pointer;
    border-right: 3px solid transparent;
}

.fab-list-entry-01.active, .fab-list-entry-01:hover
{
    border-right: 3px solid #212121; 
    background-color: #eceff1;
}

.fab-list-entry-01-activeEntry {
    border-right: 3px solid transparent;
}

.fab-background-wallpaper-01 {
    background: url('../../images/gallery/chat-bg.jpg') repeat scroll 0 0;
}

.highlight_01 {
    border-radius: 3px;
    animation: highlight 1000ms ease-out;
  }
  @keyframes highlight {
    0% {
      background-color: red;
    }
    100% {
      background-color: white;
    }
  }

.fab-main-base-list-header-01 {
    max-width: 100vw;
    border: 1px solid #bdbdbd; 
    border-top-right-radius: 5px;
    background-color: azure;
}

.trash_bin:after
{
    font-family: 'Material Icons';
    font-size: 18px;

    content: 'keyboard_arrow_right';

    color: #0000ff;
}
